import * as Msdyn365 from '@msdyn365-commerce/core';
import { IActionContext } from '@msdyn365-commerce/core';

export function addCatalogIdToUrl(url: string | undefined, ctx: IActionContext): string {
    const catalogId = Msdyn365.getCatalogId(ctx.requestContext).toString();
    const query = { ...(ctx && ctx.requestContext && ctx.requestContext.query) } || {};
    // override the catalogid in query when we passed the catalogId
    if (ctx.requestContext?.user?.isB2b && catalogId) {
        query['catalogid'] = catalogId;
    }
    if (
        ctx.requestContext?.user?.isB2b &&
        ((query['catalogid'] && query['catalogid'] !== '0') ||
            (ctx.requestContext?.user?.catalogId && ctx.requestContext.user.catalogId !== '0'))
    ) {
        query['catalogid'] = query['catalogid'] || ctx.requestContext?.user?.catalogId || '';
    } else {
        delete query['catalogid'];
    }
    return Msdyn365.appendQueryParams(url, query, ctx.requestContext?.url?.requestUrl?.hostname);
}
